import { createSlice } from '@reduxjs/toolkit'

const   initialState ={
    refreshinterval: 0, 
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    updateRefreshesettings: (state,action) => {
        if(!isNaN(parseInt(action.payload)))
            state.refreshinterval = parseInt(action.payload)
    }
  }
});

export const { updateRefreshesettings } = settingsSlice.actions
export default  settingsSlice.reducer;