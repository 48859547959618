import { useSelector, useDispatch } from 'react-redux'



export const getLeads = async (token,leadset, numrows) => {

    try {
        const url ="https://appserver.edwardspower.co.uk:3446/leads/getleads";
        const requestOptions = {
        method: 'POST',
        headers: new Headers({'Content-Type': 'application/json','Authorization' : 'Bearer ' + token }),
        body: JSON.stringify({ numrows, leadset})
        };
        const res = await fetch(url, requestOptions)
        if(res.status ===200) {
            const ret = await res.json()
            let data = JSON.parse(JSON.stringify(ret.data))
            const ilen = data.length;
            for(let i=0; i<ilen; i++)
            data[i].id = i+1;
            return {status: true, data, err: ''}
        }else {
            return {status: false, data: null, err: `API Access returned with ${res.msg} (${res.status})`}
        }
    }catch(err) {
        return {status: false, data: null, err: `API Access returned with Catch Error ${err}`}
    }
}

// Get a Single Lead for a given _id
export const getLead = async (token,id) => {

    try {
        const url =`https://appserver.edwardspower.co.uk:3446/leads/getlead/${id}`;
        const requestOptions = {
        method: 'GET',
        headers: new Headers({'Content-Type': 'application/json','Authorization' : 'Bearer ' + token })
        };
        const res = await fetch(url, requestOptions)
        if(res.status ===200) {
            const ret = await res.json()
            let data = JSON.parse(JSON.stringify(ret.data))
            const ilen = data.length;
            for(let i=0; i<ilen; i++)
            data[i].id = i+1;
            return {status: true, data, err: ''}
        }else {
            return {status: false, data: null, err: `API Access returned with ${res.msg} (${res.status})`}
        }
    }catch(err) {
        return {status: false, data: null, err: `API Access returned with Catch Error ${err}`}
    }
}

export const updateLead = async (token, lead) => {

    const url ="https://appserver.edwardspower.co.uk:3446/leads/updatelead";
    const requestOptions = {
      method: 'PUT',
      headers: new Headers({'Content-Type': 'application/json','Authorization' : 'Bearer ' + token }),
      body: JSON.stringify({ paramArr: lead})
    };
    const res = await fetch(url, requestOptions)
    let data = null
    if(res.status===200) {
      data = await res.json()
      return {status: true, data, err: ''}
    }
    return {status: false, data: null, err: `Update failed. ${res.msg} (${res.status})` }

}

export const deleteLead = async (token, lead_id) => {

    const url =`https://appserver.edwardspower.co.uk:3446/leads/deletelead/${lead_id}`;
    const requestOptions = {
      method: 'DELETE',
      headers: new Headers({'Content-Type': 'application/json','Authorization' : 'Bearer ' + token }),
    };
    const res = await fetch(url, requestOptions)
    let data = null
    if(res.status===200) {
      data = await res.json()
      return {status: true, data, err: ''}
    }
    return {status: false, data: null, err: `Delete failed. ${res.msg} (${res.status})` }

}