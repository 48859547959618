import React, { useState, useEffect, useCallback} from 'react';
import './App.css';

import Button from '@mui/material/Button';
import { createSvgIcon } from '@mui/material/utils';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {
    GridRowModes,
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridActionsCellItem,
    GridRowEditStopReasons,
    gridRowIdsSelector,
    gridPaginatedVisibleSortedGridRowIdsSelector,
    gridSortedRowIdsSelector,
    gridExpandedSortedRowIdsSelector,
    useGridApiContext,
    GridColumnsPanel,
} from '@mui/x-data-grid';

import { useSelector, useDispatch } from 'react-redux'

import RecordEdit from './RecordEdit'

import {
    getLeads,
    updateLead,
    deleteLead
} from './APIS/leadsAPI'

import {getAllDDs, getDD, getlkuptables} from './APIS/datadictAPI'
import { iconButtonClasses } from '@mui/material';

const getRowsFromCurrentPage = ({ apiRef }) =>{
  gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);
}

const getUnfilteredRows = ({ apiRef }) => {
  gridSortedRowIdsSelector(apiRef)
};

const getFilteredRows = ({ apiRef }) => gridExpandedSortedRowIdsSelector(apiRef);

const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  'SaveAlt',
);


function CustomToolbar() {
  const apiRef = useGridApiContext();

  const handleExport = (options) => apiRef.current.exportDataAsCsv(options);

  const buttonBaseProps = {
    color: 'primary',
    size: 'small',
    startIcon: <ExportIcon />,
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector
        slotProps={{ tooltip: { title: 'Change density' } }}
      />
      <Box sx={{ flexGrow: 1 }} />
      <Button
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getRowsFromCurrentPage })}
      >
        Current page rows
      </Button>
      <Button
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getFilteredRows })}
      >
        Filtered rows
      </Button>
      <Button
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}
      >
        All row
      </Button>
    </GridToolbarContainer>
  );
}





function GridView() {
  const[rows,setRows]=useState([])
  const [selrow,setSelectedRow]=useState({})
  const [numrows,setNumRows]= useState(25)
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  const [isloading,setIsLoading] = useState(false)
  const [leadset, setLeadSet] = useState("")
  const [leadsets, setLeadSets] = useState([])
  const [openeditor, setOpenEditor] = useState(false)
  const [selectedid, setSelectedId] = useState(null)
  const [griddensity, setGridDensity] = useState('compact')
  const [dlgconfirmdelete, setConfirmDelete]= useState(false)
  const [rowtodelete,setRowToDelete]=useState(null)
  const [columnsloaded,setColumnsLoaded]=useState(false)
  const [columns,setColumns] = useState([])
  const auth = useSelector(state => state.auth.auth)
  const [refreshPage,setRefreshPage] = useState(false)
  const [refreshtimerid, setRefreshTimerid]=useState(null)
  const [lasttimeinterval, setLastTimerInterval]= useState(null)
  const [refreshcnt, setRefreshCnt]= useState(0)
  // State from Redux
  const systables = useSelector((state) => state.systables)
  const refreshinterval = useSelector((state) => {
    console.log(`inside useSelector get start in GridView`)
    console.log(state);
    return state.settings.refreshinterval
  })

  console.log(`refreshinterval set to.... ${refreshinterval}`)




  const handleViewClick = (id) => {
      const srch = rows.find(item => item.id === id)
      if(srch) {
        setSelectedRow(srch)
        setSelectedId(srch._id)
        setOpenEditor(true)
      }
  }

  const hideEditor = () => { setOpenEditor(false) }
  const onPageSizeChange = (gridmodel, details) => {
    console.log(gridmodel)
    console.log(details)
    //setNumRows(gridmodel.pageSize)
    setPaginationModel(gridmodel)
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRowToDelete(id)
    setConfirmDelete(true)
  };

  const confirmDelete = async () => {
    const id = rowtodelete
    const srch = rows.find((row) => row.id === id)
    if(srch) {
      setRows(rows.filter((row) => row.id !== id)); 
      await deleteLead(auth.token, srch._id);
    }
    setConfirmDelete(false)
    setRowToDelete(null)
  }

  const cancelDelete = (id) => {
    setConfirmDelete(false)
    setRowToDelete(null)
  }

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    console.log('processNewRowUpdate')
    console.log(newRow)
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    // Update in Mongo DB
    await updateLead(auth.token, newRow);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    console.log(`newRowModesModel set to ${newRowModesModel}`)
    setRowModesModel(newRowModesModel);
  };



  const actions = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const canedit = auth.security.canedit??false

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        if(canedit)
        return [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="Edit / View"
            className="textPrimary"
            onClick={() => handleViewClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];

        return [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="Edit / View"
            className="textPrimary"
            onClick={() => handleViewClick(id)}
            color="inherit"
          />
        ]
      },
    }];

    // Get All of the leadsets Available
    useEffect(()=>{
      // fetch leadsets
      async function GetLeadSets() {
        const ret = await getAllDDs(auth.token)
        if(ret.status) {
          const lsets = await ret.data.map(item => {return {_id: item._id, leadset: item.leadset, description:item.description} })
          setLeadSets(lsets)
        }
      }
      GetLeadSets();
    },[])

    useEffect(()=> {
      const getData = async () => {
        setIsLoading(true)
        const ret = await getLeads(auth.token,leadset, 20000)
        if(ret.status) {
          setRows(ret.data)
        }

        // Now fill in any Select Items
        if(columns.length ===0 && !columnsloaded) {
          const ret = await getDD(auth.token, leadset)
          if(ret.status) {
            const ddict = ret.data
            console.log(`Gte Columns from teh Data Dictionary`)
            console.log(ddict.fields)
            let tmpcolumns = JSON.parse(JSON.stringify([...ddict.fields])) 
            for(let i=0; i<tmpcolumns.length; i++) {
              if(tmpcolumns[i].type==='singleSelect') {
                const lkupflds = tmpcolumns[i].valueLookup
                var lkupitems = null
                if(lkupflds.leadset === 'system') {
                  lkupitems = await getReduxSysTable(lkupflds.table)
                }else {
                  const lkup = await getlkuptables(auth.token,lkupflds.leadset, lkupflds.table)
                  if(lkup.status && typeof lkup.data !== "undefined" && Array.isArray(lkup.data) ) 
                    lkupitems = lkup.data
                }
                if(lkupitems && Array.isArray(lkupitems) ) {
                  var opts = []
                  lkupitems.forEach((entry) => opts.push(entry))
                  tmpcolumns[i].valueOptions = opts
                }
              }
              if(typeof tmpcolumns[i].editable !== "undefined" && typeof tmpcolumns[i].editable ==="string" && tmpcolumns[i].editable.substring(0,4) === "auth")
                tmpcolumns[i].editable = eval(tmpcolumns[i].editable)
              
              if(typeof tmpcolumns[i].valueGetter !== "undefined")
                tmpcolumns[i].valueGetter = Function("value",tmpcolumns[i].valueGetter)
            }
            setColumns(tmpcolumns)
            setColumnsLoaded(true)
          }
        }
        setIsLoading(false)
      }
      if(leadset > "" )
        getData()
    },[columns,auth.token,refreshPage, leadset])
  


  useEffect(() => {
    if(refreshinterval === 0) {
      if(refreshtimerid !== null) {
        // Timer is set so we need to clear it
        clearInterval(refreshtimerid)
        setRefreshTimerid(null)
        setLastTimerInterval(refreshinterval)
      }
    }else {
      if(refreshinterval > 0 ) {
        if(refreshtimerid === null) {
          // No Timer is set to create a new one
          const timerid = setInterval(refreshData, (refreshinterval * 60000))
          setRefreshTimerid(timerid)
          setLastTimerInterval(refreshinterval)
        }else {
          // If the interval has changed - Clear existing timer and create a new one.
          if(refreshinterval !== lasttimeinterval) {
            clearInterval(refreshtimerid)
            const timerid = setInterval(refreshData, (refreshinterval * 60000))
            setRefreshTimerid(timerid)
            setLastTimerInterval(refreshinterval)
          }
        }
      }
    }
  },[refreshinterval,refreshtimerid,lasttimeinterval])

  const refreshData = useCallback(() => {
    setRefreshPage(prevVal => !prevVal)
    setRefreshCnt(prevVal => prevVal+1)
  },[refreshPage]);

  const getReduxSysTable = async (tablename) => {
    switch(tablename) {
      case 'users':
        return systables.users;
      case 'titles':
        return systables.titles;
      case 'statuses':
        return systables.statuses;
      default: return [];
    }
  }

  const handleLeadSetChange = (e) => {
    setColumns([])
    setColumnsLoaded(false)
    setLeadSet(e.target.value)
  }
  //if(isloading) 
  //  return (<h4>Loading Data....</h4>)
  const onDensityChange = (e) => {
    setGridDensity(e)
  }
  if(!isloading)
    return (
    <Paper sx={{ width: '100%', textAlign: 'center'}}>
      {openeditor && <RecordEdit open={openeditor} id={selectedid} data={selrow} cols={columns} setOpen={hideEditor} onUpdate={processRowUpdate} /> }
      <FormControl sx={{ m: 1, minWidth: 300 }}>
        <InputLabel id="lbl-leadset-select">Select Required Lead set</InputLabel>
        <Select           
          labelId="lbl-leadset-select"
          id="leadset-select"
          value={leadset}
          onChange={handleLeadSetChange}
          label="Select Leadset"
          size="small"
          autoWidth
        >
          {leadsets.map((item,index) => {
            return(<MenuItem key={`dset_${index}`} value={item.leadset} >{item.description}</MenuItem>)
          })}
        </Select>
      </FormControl>

      {columnsloaded && leadset &&
          <DataGrid
            sx={{'--DataGrid-overlayHeight': '400px', margin: '10px 10px 10px 10px'}}
            ignoreDiacritics={true}
            paginationMode="client"
            onPaginationModelChange={onPageSizeChange}
            rows={rows}
            columns={[...columns,...actions]}
            loading={isloading}
            density={griddensity}
            onDensityChange={onDensityChange}
            initialState={{ 
              pagination: { rowCount: -1 },   
              sorting: {
                sortModel: [{ field: 'created', sort: 'desc' }],
              },
              columns: {    
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  _id: false,
                  downloaddate: false
                } 
              }
            }}
            pageSizeOptions={[5,10,20,25,50,100]}
            paginationModel = {paginationModel}
            checkboxSelection={true}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            autosizeOnMount={true}
            slots={{ toolbar: CustomToolbar }}
            />
      }
    <br/><Button onClick={()=>{setRefreshPage(prevVal => !prevVal)}} >Refresh</Button>
    </Paper>
  );
}

export default GridView;
