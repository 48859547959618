
import * as React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { withStyles } from '@mui/styles';


import { useSelector, useDispatch } from 'react-redux'
import { updateRefreshesettings} from './slices/settingsSlice.js'


import logo from './assets/logo.png'
import { gridColumnGroupsLookupSelector } from '@mui/x-data-grid';

const styles = {
    logo: { 
        marginTop: 'auto', 
        marginBottom: 'auto' 
    }
}
const drawerWidth = 240;
const navItems = [
  {id: 'home', label: 'Home', fnc: 'openHomeFNC'}, 
  {id: 'settings', label:'Setting', fnc: 'openSettingsFNC'}
];

const settingspages = [
  {id: 'userset', label: 'Users', url: 'useradmin', fnc: 'userSettingsMnu'},
  {id: 'secretset', label: 'Secrets', url:'secretadmin', fnc: 'TestFNC'},
  {id: 'statusset', label: 'Statuses', url: 'statusadmin', fnc: 'TestFNC'},
  {id: 'convset',label: 'Conversion Points',url: 'conversions', fnc: 'TestFNC'},
  {id: 'leadset', label: 'Lead Sets', url: 'leadset', fnc: 'TestFNC'},
  {id: 'formtest', label: 'Form Test', url: 'forms', fnc: 'Forms'},

]

const userinfo = [{id: 'logout', label: 'Logout', fnc: 'handleCloseUserMenu' }];
const refreshrates = [
  {duration: 0, label: 'No Auto Refresh'},
  {duration: 1, label: '1 Minute' },
  {duration: 2, label: '2 Minutes' },
  {duration: 3, label: '3 Minutes' },
  {duration: 4, label: '4 Minutes' },
  {duration: 5, label: '5 Minutes' },
  {duration: 10, label: '10 Minutes' },
  {duration: 15, label: '15 Minutes' },
  {duration: 20, label: '20 Minutes' },
  {duration: 25, label: '25 Minutes' },
  {duration: 30, label: '30 Minutes' },
  {duration: 60, label: 'Hourly' },
  {duration: 360, label: '6 Hours' },
  {duration: 720, label: '12 Hours' },
  {duration: 1440, label: '24 Hours' },
]
function EPAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorElSetting, setAnchorElSetting] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const classes = props.classes
  const auth = useSelector(state  => state.auth)
  const settings = useSelector(state => state.settings)
  const dispatch = useDispatch()

  const [refreshinterval, setRefreshInterval] = React.useState(0);

  const handleCloseUserMenu = () => { setAnchorElUser(null) }
  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget)
  }
  const handleSettingMenu = (item) => (e) => {
    switch(item) {
      case 'openSettingsFNC':
        setAnchorElSetting(e.currentTarget)
        break;
      case 'openHomeFNC':
        props.menuSelect('gridview')
        break;
      case 'userSettingsMnu':
        props.menuSelect('useradmin')
        setAnchorElSetting(null);
        break;
      case 'handleCloseUserMenu':
        props.menuSelect('logout')
        setAnchorElSetting(null);
        break;
      case 'TestFNC':
        console.log('TestFNC clicked');
        props.menuSelect('comingsoon')
        setAnchorElSetting(null);
        break;
      case 'UpdateDataDict':
          props.menuSelect('createdatadict')
          setAnchorElSetting(null);
          break;
      case 'Forms':
        props.menuSelect('forms')
        setAnchorElSetting(null);
        break;
  
    }
  }
  const handleCloseSettingMenu = () =>{setAnchorElSetting(null)}
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const updateRefreshTime = (e) => {
    console.log(`refreshinterval set to ${e.target.value}`)
    setRefreshInterval(e.target.value)
    dispatch(updateRefreshesettings(e.target.value) ) 
    setAnchorElSetting(null)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle}sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Edwards & Power
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.id} disablePadding={true} >
            <ListItemButton sx={{ textAlign: 'center' }} onClick={handleSettingMenu(item.fnc)}  >
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="static" component="nav">
        <Toolbar >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} alt='Edwards & Power' className={classes.logo} height="50" /><br/>
          <Box sx={{ flexGow:1, display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button 
                aria-controls={Boolean(anchorElSetting) ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorElSetting) ? 'true' : undefined}
                key={item.id} sx={{ color: '#fff' }} 
                onClick={handleSettingMenu(item.fnc) } 
              >
                {item.label}
              </Button>
            ))}

              <Menu
                id="menu-appbar-settings"
                anchorEl={anchorElSetting}
                open={Boolean(anchorElSetting)}
                onClose={handleCloseSettingMenu}
                MenuListProps={{
                'aria-labelledby': 'menu-appbar-settings',
                }}
            >
                <MenuItem disabled={true} >Setting Menu</MenuItem>
                <hr/>
                {settingspages.map((setting)=> {
                  return (
                    <MenuItem key={setting.id} onClick={handleSettingMenu(setting.fnc)}>
                      <Typography sx={{ textAlign: 'center' }}>{setting.label}</Typography>
                    </MenuItem>
                  )
                })}
                    <MenuItem key='refresh_rate'>
                      Refresh Time <select id='refresh'  value={refreshinterval}onChange={updateRefreshTime} >
                        { refreshrates.map( (item,index) => {
                            return (<option key={`refresh${index}`}  value={`${item.duration}`}  >{item.label}</option>)
                          })
                        }
                      </select>
                    </MenuItem>
            </Menu>
          </Box>
          <Typography  sx={{ flexGrow: 1 }}></Typography>
          <Box  sx={{ flexGrow: 0 }}>
            <Tooltip title="Open">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={auth.auth.user??'User'} src={auth.auth.avatar??'/static/images/avatar/2.jpg'} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
                <MenuItem key='loggedonas' disabled={true} >
                  <Typography sx={{ textAlign: 'center' }}>Logged on as {auth.auth.user}</Typography>
                </MenuItem>
                <hr/>
              {userinfo.map((info) => (
                <MenuItem key={info.id} id={info.id} onClick={handleSettingMenu(info.fnc)}>
                  <Typography sx={{ textAlign: 'center' }}>{info.label}</Typography>
                </MenuItem>
              ))}
              <MenuItem key='refresh_rate' >
                Refresh Time&nbsp;-&nbsp;<select id='refresh' value={refreshinterval} onChange={updateRefreshTime} >
                  { refreshrates.map( (item,index) => {
                      return (<option key={`refresh${index}`}  value={`${item.duration}`}  >{item.label}</option>)                    })
                  }
                </select>
              </MenuItem>

            </Menu>
          </Box>

        </Toolbar>

      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      </Box>
  );
}

EPAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default withStyles(styles)(EPAppBar)
