import React, { Component,useState, useEffect, useCallback, useRef} from 'react';
import './css/Forms.css';
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid2';
import SvgIcon from '@mui/material/SvgIcon';
import {useDropzone} from 'react-dropzone'

const FileUpload = (props) => {
    const [dragActive, setDragActive] = React.useState(false);
    const [targetClass,setTargetClass]=useState('uploaddroparea');
    const inputRef = useRef(null);

    const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader()
  
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
        // Do whatever you want with the file contents
          const binaryStr = reader.result
          console.log(binaryStr)
        }
        reader.readAsArrayBuffer(file)
      })
      
    }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop})

  // handle drag events
  const handleDrag = function(e) {
    e.stopPropagation();
    e.preventDefault();
    console.log('handleDrag')
    console.log(e)
    console.log('*** DRAG ACTIVE SO WE CAN DROP ***')
    setTargetClass("uploaddroparea hoverblue")
  };
  
  const handleDragLeave = function(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log('>>>> DRAG LEAVE SO WE CANT DROP <<<<')
    setTargetClass("uploaddroparea")
  }
  // triggers when file is dropped
  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();
    console.log('handleDrop')
    console.log(e)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
      console.log('handleDrop')
      console.log(e.target.files)
    }
  };
  
  // triggers when file is selected with click
  const handleChange = function(e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      // handleFiles(e.target.files);
      console.log('handle change')
      console.log(e.target.files)
    }
  };
  
// triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };
    return (
      <div className="container">
        <div className='gridContainer' >
          <div className="uploadouter uploadinner" >
            <div className="uploadtextcontainer" >
              <div>
                <div className="uploadtextcontainer">
                  <Typography className='uploadheadertext'>Please upload your photo ID.</Typography>
                </div>
                <Typography className="uploadsubheadertext">You can upload your passport or drivers license</Typography>
              </div>
              <span></span>
            </div>
            <Box sx={{width:100, marginTop: '20px', marginBottom: 0}}></Box>
            <div id="droptarget" className={targetClass} {...getRootProps()} >
              <input {...getInputProps()} ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} style={{display: 'none'}}/>
              <SvgIcon>
                <svg 
                  focusable="false" 
                  viewBox="0 0 71 71" 
                  aria-hidden="true" 
                  fill="none" 
                  style={{fontSize: "60px"}}
                >
                  <path d="M52.7144 67.4669C54.6708 67.4669 56.5471 66.6897 57.9305 65.3063C59.3139 63.9229 60.0911 62.0467 60.0911 60.0902V30.1611C60.0909 28.8573 59.5729 27.6069 58.6511 26.6849L36.9423 4.97611C36.0203 4.05428 34.7699 3.53633 33.4661 3.53613H18.2902C16.3338 3.53613 14.4575 4.31331 13.0741 5.6967C11.6908 7.08008 10.9136 8.95635 10.9136 10.9128V60.0902C10.9136 62.0467 11.6908 63.9229 13.0741 65.3063C14.4575 66.6897 16.3338 67.4669 18.2902 67.4669H35.5023" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"></path>
                  <path d="M35.5 4.76367V23.2052C35.5 24.5095 36.0181 25.7603 36.9404 26.6826C37.8626 27.6049 39.1135 28.123 40.4177 28.123H58.8593" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"></path>
                  <path d="M51.1998 61.4998H36.7998M43.9998 54.2998V68.6998V54.2998Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"></path>
                </svg>
              </SvgIcon>
              <div className="uploadbuttondroparea">
                <Button onClick={onButtonClick} variant='contained' color='secondary' size="small" >Choose a file</Button>
              </div>
              <span className='dropareatext '>Or drop a file to upload</span>
            </div>
          </div>
        </div>
      </div>
    )
}



let renderCount = 0;

function FormsBuilder() {
	const [schema, setSchema] = React.useState([
		{
			id: "123",
			type: "a",
			text: "123-text"
		},
		{
			id: "345",
			type: "b",
			text: "345-text"
		},
		{
			id: "567",
			type: "a",
			text: "567-text"
		},
		{
			id: "789",
			type: "b",
			text: "789-text"
		}
	]);

	const onDragEnd = (result) => {
    console.log('onDragEnd')
    console.log(result)
    console.log('*******')
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		// reorder using index of source and destination.
		const schemaCopy = schema.slice();
		const [removed] = schemaCopy.splice(result.source.index, 1);
		// put the removed one into destination.
		schemaCopy.splice(result.destination.index, 0, removed);

		console.log(result);

		setSchema(schemaCopy);
	};
	return (

		<div className="container">
      <p>&nbsp;</p>
      <p>&nbsp;</p>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="column1">
					{(provided, snap) => (
						<div
							ref={provided.innerRef}
							{...provided.droppableProps}
						>
							{schema.map((it, i) => {
                console.log(`${i} ${it}`)
                return (
								<Draggable
									key={it.id}
									draggableId={it.id}
									index={i}
                  id={it.id}
								>
									{(provided, snap) => (
										<Card
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											className="list-item"
											style={{
												backgroundColor: snap.isDragging
													? "#d3f0fc"
													: "#eee",

												...provided.draggableProps.style
											}}
										>
                      <CardContent>
											{it.text}
                      </CardContent>
										</Card>
									)}
								</Draggable>
							)})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	);
}



export default  FormsBuilder

